import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItem, addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'
import { Money } from '@/modules/shared/utils/money'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type Transfer = {
  id: number | null
  capital: Money
  commitment: {
    id: number | null
    capital: Money
    carried_interest_percentage: number | null
    investor: {
      name: string | null
    }
    management_fee_percentage: number | null
    ownership_without_fees_by_called_amount: number | null
    preferred_return_percentage: number | null
  }
  carried_interest_distributed: Money
  interest: Money
  other_fee_distributed: Money
  profit: Money
  return_of_capital: Money
}

export type Disbursement = {
  id: number | null
  prior: boolean
  date: string | null
  notes: string | null
  wiring_instructions: string | null
  selected_capital_type: '%' | '$'
  currency: string | null
  entered_capital_amount: Money | null
  entered_interest_earned_amount: Money | null
  entered_carried_interest_amount: number | null
  entered_other_fees_amount: Money | null
  disbursement_transfers: Transfer[]
  fund_total_capital_committed: Money
  is_recallable: boolean
  portfolio_disbursement_allocation_id: number | null
  type: 'cash' | 'asset' | 'recycled'
  disbursement_allocation_type:
    | 'Equity'
    | 'Convertible Note'
    | 'Loan'
    | 'Revenue Share'
    | 'Option'
    | 'Warrant'
    | 'Safe Note'
  config: {
    allocate_carried_interest: boolean
  }
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type DisbursementMap = Map<CID, Disbursement>

export const useDisbursementStore = defineStore('investing/disbursementStore', () => {
  const route = useRoute()
  const baseUrl = computed(() => `/${route.params.slug}/investing`)

  const items = ref<DisbursementMap>(new Map())
  const disbursements = computed(() => Array.from(items.value.keys()).map((key) => items.value.get(key)))

  const fetchDisbursement = async (
    investable_type,
    investable_id,
    disbursement_id = null,
    portfolio_disbursement_allocation_id = null,
  ) => {
    let url = `${baseUrl.value}/${investable_type}/${investable_id}/disbursement`
    url += disbursement_id ? `/${disbursement_id}` : '/new'
    url += portfolio_disbursement_allocation_id
      ? `?portfolio_disbursement_allocation_id=${portfolio_disbursement_allocation_id}`
      : ''
    const { data, error } = await useFetch(url).get().json<{ data: Disbursement[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }

    const disbursement = data.value.data
    addItem(items, { ...disbursement, _cid: disbursement.id })
  }

  const addDisbursement = async (disbursement) => {
    const { data, error } = await useFetch(`${baseUrl.value}/disbursement/add`).post({ disbursement }).json<{}>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }
  }

  const updateDisbursement = async (disbursement) => {
    const { data, error } = await useFetch(`${baseUrl.value}/disbursement/update`).put({ disbursement }).json<{}>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }
  }

  return {
    items,
    disbursements,

    fetchDisbursement,
    addDisbursement,
    updateDisbursement,
  }
})

import { useLocalStorage } from '@vueuse/core'
import { Column, unflattenKey } from '@/modules/shared/utils/v-table'
import { entity_columns } from '@/modules/investing/config/columns'
import { initialMoney, sum } from '@/modules/shared/utils/money'
import { CID } from '@/modules/shared/utils/store.types'

const csv_columns: Column[] = [...entity_columns]

const columns_to_display = (email, slug) => {
  const key_name = `${email}_${slug}_entity_columns`

  // this is to update the columns in storage when there are changes in entity columns
  let columns_in_storage = useLocalStorage(key_name, entity_columns)
  let columns = entity_columns
  columns.forEach((column, index) => {
    if (columns_in_storage.value[index] === undefined || columns_in_storage.value[index] === null) return
    column.is_visible = columns_in_storage.value[index].is_visible
  })
  columns_in_storage.value = columns

  return columns_in_storage
}

function sanitizeURI(uri: string): string {
  // Replace invalid characters with a hyphen, preserve valid characters
  const sanitized = uri.toLowerCase().replace(/[^a-z0-9]+/g, '-')
  // Remove leading and trailing hyphens
  return sanitized.replace(/^-+|-+$/g, '').trim()
}

const toParam = (entity: any) => sanitizeURI(`${entity.id}-${entity.name}`)

// entities should be transformed first using transformEntity
const getChartData = (entities: any[], enableFilter: boolean, filterKeys: any[] = []) => {
  let committed = []
  let committed_remaining = []
  let calls = []
  let scheduled_calls = []
  let distributions = []

  entities.forEach((entity) => {
    committed.push(entity.committed_array)
    committed_remaining.push(entity.committed_remaining_array)
    calls.push(entity.calls)
    scheduled_calls.push(entity.scheduled_calls)
    distributions.push(entity.distributions)
  })

  calls = calls.flat()
  scheduled_calls = scheduled_calls.flat()
  distributions = distributions.flat()

  if (enableFilter) {
    calls = calls.filter((call) => filterKeys.includes(call._cid))
    distributions = distributions.filter((distribution) => filterKeys.includes(distribution._cid))
  }

  return {
    committed: sum(committed),
    committed_remaining: sum(committed_remaining),
    called_received: sum(calls.map((call) => call.total_capital).concat(initialMoney)),
    called_received_capital: sum(calls.map((call) => call.capital).concat(initialMoney)),
    called_received_management_fees: sum(calls.map((call) => call.management_fees).concat(initialMoney)),
    called_received_other_fees: sum(calls.map((call) => call.other_fees).concat(initialMoney)),
    called_pending: sum(calls.map((call) => call.total_capital_pending).concat(initialMoney)),
    called_pending_capital: sum(calls.map((call) => call.capital_pending).concat(initialMoney)),
    called_pending_management_fees: sum(calls.map((call) => call.management_fees_pending).concat(initialMoney)),
    called_pending_other_fees: sum(calls.map((call) => call.other_fees_pending).concat(initialMoney)),

    hurdle: sum(distributions.map((distribution) => distribution.hurdle).concat(initialMoney)),
    distribution_distributed: sum(
      distributions.map((distribution) => distribution.net_distributed).concat(initialMoney),
    ),
    distribution_distributed_capital: sum(
      distributions.map((distribution) => distribution.capital).concat(initialMoney),
    ),
    distribution_preferred_return: sum(
      distributions.map((distribution) => distribution.preferred_return).concat(initialMoney),
    ),
    distribution_interest_earned: sum(distributions.map((distribution) => distribution.interest).concat(initialMoney)),
    distribution_distributed_profit: sum(distributions.map((distribution) => distribution.profit).concat(initialMoney)),
    distributed_carried_interest: sum(
      distributions.map((distribution) => distribution.carried_interest_distributed).concat(initialMoney),
    ),
    distributed_other_fees: sum(
      distributions.map((distribution) => distribution.other_fee_distributed).concat(initialMoney),
    ),
    distribution_pending: initialMoney,
    distribution_pending_capital: initialMoney,
    distribution_pending_profit: initialMoney,
    distribution_pending_carried_interest: initialMoney,
    distribution_pending_other_fees: initialMoney,
    distribution_remaining: sum(distributions.map((distribution) => distribution.remaining).concat(initialMoney)),
  }
}

const isEntityAdmin = (entity, cid) => {
  const admins_ids = unflattenKey(entity, 'managers').map((manager) => manager.id)
  const type = cid.split(':')[0]
  const id = cid.split(':')[1]

  if (type === 'individual') return admins_ids.includes(parseInt(id))
  return false
}

const transformEntity = (entities: any[], cid: CID, enableFilter: boolean, filterKeys: any[] = []) => {
  let data = entities

  if (enableFilter) {
    data = data.filter((entity) => {
      if (isEntityAdmin(entity, cid)) return true
      return entity.investors.some((investor) => filterKeys.includes(investor._cid))
    })
  }
  return data.map((entity) => {
    const called_array = entity.called_array
      .filter((c) => (!enableFilter ? true : filterKeys.includes(c._cid)))
      .map((c) => c.value)
      .concat(initialMoney)
    const carried_interest_array = entity.carried_interest_array
      .filter((c) => (!enableFilter ? true : filterKeys.includes(c._cid)))
      .map((c) => c.value)
      .concat(initialMoney)
    const committed_array = entity.committed_array
      .filter((c) => (!enableFilter ? true : filterKeys.includes(c._cid)))
      .map((c) => c.value)
      .concat(initialMoney)
    const committed_remaining_array = entity.committed_remaining_array
      .filter((c) => (!enableFilter ? true : filterKeys.includes(c._cid)))
      .map((c) => c.value)
      .concat(initialMoney)
    const distributed_array = entity.distributed_array
      .filter((c) => (!enableFilter ? true : filterKeys.includes(c._cid)))
      .map((c) => c.value)
      .concat(initialMoney)
    const management_fee_array = entity.management_fee_array
      .filter((c) => (!enableFilter ? true : filterKeys.includes(c._cid)))
      .map((c) => c.value)
      .concat(initialMoney)
    const other_fees_array = entity.other_fees_array
      .filter((c) => (!enableFilter ? true : filterKeys.includes(c._cid)))
      .map((c) => c.value)
      .concat(initialMoney)

    return {
      ...entity,
      called_array: sum(called_array),
      carried_interest_array: sum(carried_interest_array),
      committed_array: sum(committed_array),
      committed_remaining_array: sum(committed_remaining_array),
      distributed_array: sum(distributed_array),
      management_fee_array: sum(management_fee_array),
      other_fees_array: sum(other_fees_array),
    }
  })
}

export { csv_columns, columns_to_display, getChartData, isEntityAdmin, toParam, transformEntity }
